import SpeedDatingData from '../assets/images/ThumbnailSPD.png';
import ConcaDS from '../assets/images/ThumbnailConcaDS.jpg';
import Conca from '../assets/images/ThumbnailConca.jpg';
import Samsung1 from '../assets/images/ThumbnailSamsung1.jpg';
import Samsung2 from '../assets/images/ThumbnailSamsung2.jpg';
import Samsung3 from '../assets/images/ThumbnailSamsung3.jpg';
import DSG from '../assets/images/Thumbnail3.jpg';
import mamaPay from '../assets/images/Thumbnail1.jpg';
import TaguJam from '../assets/images/Thumbnail2.jpg';
import FireTV from '../assets/images/Thumbnail4.jpg';
import VDay from '../assets/images/Thumbnail5.jpg';
import Launchpad from '../assets/images/Thumbnail6.jpg';
import BTS from '../assets/images/Thumbnail7.jpg';
import AmazonJP from '../assets/images/Thumbnail8.jpg';
import Amazon from '../assets/images/Thumbnail9.jpg';

const ThumbnailsData = [
	{
    imgPath: SpeedDatingData,
    imgAlt: "Visualizing Shifts in People's Perceptions",
    title: "Visualizing Shifts in People's Perceptions",
    desc: "Changes in perceptions, attitudes, and beliefs over time",
    goTo: "/ux/0",
  },
	{
    imgPath: ConcaDS,
    imgAlt: "Conca Design System",
    title: "Conca Design System",
    desc: "Design System for Conca Brand",
    goTo: "/ux/1",
  },
	{
    imgPath: Conca,
    imgAlt: "Conca Matching App",
    title: "Conca Matching App",
    desc: "Discover genuine connections tailored to a busy life",
    goTo: "/ux/2",
  },
	{
    imgPath: Samsung1,
    imgAlt: "Samsung Product Initiative",
    title: "Product Initiative",
    desc: "Galaxy Watch5 Model Inventory Management Initiative",
    goTo: "/ux/3",
  },
	{
    imgPath: Samsung2,
    imgAlt: "Samsung Optimized UX",
    title: "Optimized UX",
    desc: "Strategic Search Overlay: Elevating Visibility for Flagship Devices",
    goTo: "/ux/4",
  },
	{
    imgPath: Samsung3,
    imgAlt: "Samsung Cross-sell screen improvement",
    title: "UX Enhancement",
    desc: "Samsung Cross-sell screen improvement",
    goTo: "/ux/5",
  },
	{
    imgPath: DSG,
    imgAlt: "Dynamic Component Library",
    title: "Dynamic Component Library",
    desc: "Utilized Dynamic CSS, Sass, and React",
    goTo: "/ux/6",
  },
	{
    imgPath: TaguJam,
    imgAlt: "TaguJam Thumbnail",
    title: "TaguJam",
    desc: "TaguJam is everyone's market place",
    goTo: "/ux/7",
  },
  {
    imgPath: mamaPay,
    imgAlt: "mamaPay Thumbnail",
    title: "mamaPay",
    desc: "mamaPay is family currency system",
    goTo: "/ux/8",
  },
  {
    imgPath: FireTV,
    imgAlt: "FireTV Thumbnail",
    title: "FireTV",
    desc: "Amazon FireTV Campaigns",
    goTo: "/graphics/0",
  },
  {
    imgPath: VDay,
    imgAlt: "Valentine's Day Thumbnail",
    title: "Valentine's Day",
    desc: "VAmazon Marketing Event",
    goTo: "/graphics/1",
  },
  {
    imgPath: Launchpad,
    imgAlt: "Launchpad Thumbnail",
    title: "Launchpad",
    desc: "High Volumn Advertising Camapign",
    goTo: "/graphics/2",
  },
  {
    imgPath: BTS,
    imgAlt: "Back to School Thumbnail",
    title: "Back to School",
    desc: "Amazon Marketing Event",
    goTo: "/graphics/3",
  },
  {
    imgPath: Amazon,
    imgAlt: "Amazon Campaign Grphics",
    title: "Amazon",
    desc: "Various Marketing Banners",
    goTo: "/gallery/0",
  },
  {
    imgPath: AmazonJP,
    imgAlt: "AmazonJP Thumbnail",
    title: "Amazon Japan",
    desc: "Various Marketing Banners",
    goTo: "/gallery/1",
  }
];

export default ThumbnailsData;