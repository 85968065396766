import React from 'react';
import './Header.scss';
import logoPath from '../../assets/images/logo.png';
import { NavLink } from 'react-router-dom';

const Header = (props) => {
  return(
    <header className="header">
      <NavLink to="/" className="logo"><img src={logoPath} alt="Kazue" className="logoImg" /></NavLink>
      <ul className="menu">
        <li><NavLink className="navItem" to="/">Work</NavLink></li>
        <li><NavLink className="navItem" to="/about">About</NavLink></li>
      </ul>
    </header>
  )
}

export default Header;
