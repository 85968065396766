import React from 'react';
import Hero from '../../components/Hero/Hero';
import Hero1 from "../../assets/images/Home0.jpg";
import ThumbLayout from '../../components/ThumbLayout/ThumbLayout'; 
import ThumbnailsData from '../../constants/ThumbnailsData';

const Home = (props) =>{
  console.log(props);
  
  return(
    <div>
      <Hero 
        bgColor="#CED4D4"
        imgPath={Hero1} 
        imgAlt="MamaPay"
      />
      <ThumbLayout thumbnails={ThumbnailsData} />
    </div>
  )
}

export default Home;
